import React from 'react';
import {navigate, Link as GatsbyLink} from 'gatsby';

import {withFirebase} from '../Firebase';
import * as ROUTES from '../../constants/routes';
import {Link} from 'gatsby';
import '../../pages/signin.css';

const SignInFormBase = (props) => {
	
	const initialState = {
		email: '',
		password: '',
		error: null,
	};
	const isPhoneNumberVerified = false;

	const [siginInCredentials, setSignInCredentials] = React.useState({...initialState});
	const {email, password, error} = siginInCredentials;

	const handleChange = (event) => {
		setSignInCredentials({
			...siginInCredentials,
			[event.target.name]: event.target.value,
		});
	};

	const onSubmitForm = (event) => {
		props.firebase
			.doSignInWithEmailAndPassword(siginInCredentials.email, 
				siginInCredentials.password)
			.then((authUser) => {
				if(authUser.user.phoneNumber != null) {
					isPhoneNumberVerified = true;
				}
				setSignInCredentials({...initialState});
				isPhoneNumberVerified ? navigate(ROUTES.HOME) : 
					navigate(ROUTES.MOBILE_VERFICATION);
			})
			.catch((Error) => {
				setSignInCredentials({
					...siginInCredentials,
					error: Error,
				});
			});

		event.preventDefault();
	};

	return (
		<div className="contentWrap">
			<form onSubmit={onSubmitForm}>
				<input
					className="textBox"
					type="email"
					id="email"
					onChange={handleChange}
					value={email}
					name="email"
					autoComplete="email"
					autoFocus
					required
					placeholder="Email ID"
				/>
				<input
					className="textBox textBox1"
					type="password"
					value={password}
					onChange={handleChange}
					autoComplete="current-password"
					name="password"
					id="password"
					required
					placeholder="Password"
				/>

				<Link className="forgotPassword" to="/forgotPasswordEmail">
					<span>Forgot password ?</span>
				</Link>

				<button className="buttonSignin" type="submit">
					<p className="inButtonText">Proceed</p>
				</button>

				{error && <p>{error.message}</p>}
			</form>
		</div>
	);
};

const SignInForm = withFirebase(SignInFormBase);

export default SignInForm;
