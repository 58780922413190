import React from 'react';

import './signin.css';
import {Link} from 'gatsby';
import phoneIcon from '../images/icons/phone-icon.svg';
import SignInWithGoogle from '../components/SignIn/SiginWithGoogle';
import SignInWithEmailForm from '../components/SignIn';

  export default () => (
	<div className="fullScreen">
        <div className="topBar">
            <div className="logoDiv">
				<Link to="/">
                	<span className="logo">Auto</span>
                	<span className="logo text-style-1">Save</span>
				</Link>
            </div>
        </div>
        <div className="signInScreen">
            <div className="headGroup">
                <p className="header headerWithoutBack">Welcome back</p>
            </div>
            
			<SignInWithEmailForm />
			

			<div className="buttonWrap">
				<Link to="/signInWithMobile">
					<button className="buttonLink">
						<img className="iconCss" src={phoneIcon} alt="phone icon" />
						<p className="buttonLinkText">Sign in with Phone</p>
					</button>
				</Link>

				<SignInWithGoogle />
				<div className="bottomText newUserDiv">
					<span className="newUser">New user? </span>
					<Link className="aLink" to="/signup">
						<span className="newUser signUp">Sign Up</span>
					</Link>
				</div>
			</div>
        </div>
    </div>
);
